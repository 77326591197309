import TagManager from "react-gtm-module";
export const tagManagerEvents = (eventName: string, utm_source: string) => {
  // console.warn("this is event name", eventName, "utm source", utm_source);
  const tagManagerArgs = {
    dataLayer: {
      event: eventName,
      utm_source: utm_source,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagManagerParamsEvents = (
  eventName: string,
  utm_source: string,
  data: Record<string, any>
) => {
  const tagManagerParamsArgs = {
    dataLayer: {
      event: eventName,
      utm_source: utm_source,
      ...data,
    },
  };

  TagManager.dataLayer(tagManagerParamsArgs);
};
