const EventsName = {
  //Authentication
  sendOtp: "Send_OTP",
  continueAsGuest: "Continue_As_Guest",
  policyPurchased: "Policy_Purchased",
  policyActivated: "Policy_Activated",
  alreadySubscribed: "Already_Subscribed",
  login: "login",
  subscribe: "subscribe",
  // Add Account
  accountAdded: "Account_Added",
  // Claimed
  policyClaimed: "Policy_Claimed",
  claimedPolicyUpdated: "Claimed_Policy_Updated",
  //-----------------------//
  deactivatePolicy: "Deactivate_Policy",
  menuPressed: "Menu_Pressed",
  logout: "LogOut",
  notification: "Notifications",
  policyDetail: "Policy_Details",
  productBenefits: "Product_Benefits",
  planSelected: "Plan_Selected",
  setupProfilePicture: "Setup_Profile_Picture",
  setupProfile: "Setup_Profile",
  services: "Services/Policies",
  policySearched: "Policy_Searched",
  paymentMethod: "Payment_Method",
  customerSupport: "Customer_Support",
  insuranceProvider: "Insurance_Provider",
  insuranceTitle: "Insurance_Title",
};

export default EventsName;
