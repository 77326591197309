import React, { useEffect, useRef, useState } from "react";
import Landing from "../components/Landing";
import {
  generateOTPService,
  checkOperatorService,
  generateWaadaOTPService,
  activateMobileInsuranceService,
} from "../services/LandingService";
import { useNavigate } from "react-router-dom";
import { tagManagerEvents } from "../utils/GoogleTagManager";
import EventsName from "../constants/EventsName";
import LandingOne from "../components/LandingOne";

type Props = {};

const LandingWaadaScreen = (props: Props) => {
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utmSource: any = searchParams.get("utm_source");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [msisdn, setMsisdn] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [subPackage, setSubPackage] = useState("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [isOTPSending, setIsOTPSending] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const [inputValue, setInputValue] = useState({
    name: "",
    cnic: "",
    phone_make: "",
    phone_model: "",
    imei_1: "",
    imei_2: "",
  });

  const [inputError, setInputError] = useState({
    name: false,
    cnic: false,
    phone_make: false,
    phone_model: false,
    imei_1: false,
    imei_2: false,
  });

  const [errorText, setErrorText] = useState({
    name: "",
    cnic: "",
    phone_make: "",
    phone_model: "",
    imei_1: "",
    imei_2: "",
  });
  const [counter, setCounter] = useState(0); // Counter state to manage timer
  const [isCounting, setIsCounting] = useState(false); // State to track if counter is active

  const [isUserProfileModalVisible, setIsUserProfileModalVisible] =
    useState(false);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus(); // Focus on the first input
    }
  }, []);

  const onHandleCloseUserProfileModal = () => {
    if (msisdn.length === 10) {
      setErrorMsg("");
      handleCheckOperator();
    } else {
      setErrorMsg("Please enter a valid 10-digit phone number");
    }
  };

  // useEffect hook to manage the countdown when the timer starts
  useEffect(() => {
    if (isCounting && counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer); // Cleanup on component unmount or counter reset
    } else if (counter === 0) {
      setIsCounting(false); // Stop counting when counter hits zero
    }
  }, [counter, isCounting]);

  // Function to handle resend click
  const handleResendClick = async () => {
    try {
      setIsOTPSending(true);
      let response = await generateWaadaOTPService({ msisdn });

      setShowLoginButton(true);
      setIsOTPSending(false);
      setErrorMsg("");
      setCounter(30);
      setIsCounting(true);

      //    else {
      //     setIsOTPSending(false);
      //     setShowLoginButton(false);
      //     setErrorMsg("Something went wrong.Please try again");
      //   }
    } catch (error) {
      setShowLoginButton(false);
      setIsOTPSending(false);

      setErrorMsg("Something went wrong.Please try again");
    }
    // finally {
    //   setIsLoading(false);
    //   setIsOTPSending(false);
    // }
  };

  useEffect(() => {
    let userId = localStorage.getItem("user_Id");
    if (userId) {
      navigate("/home");
    }
  }, []);

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const trimmedValue = name === "name" ? value.trim() : value;

    setInputValue((prevState) => ({ ...prevState, [name]: trimmedValue }));

    // Reset errors when the user starts typing
    setInputError((prevState) => ({ ...prevState, [name]: false }));
    setErrorText((prevState) => ({ ...prevState, [name]: "" }));
  };

  // Example of validation for CNIC and Name
  //   const validateInput = (): boolean => {
  //     let valid = true;
  //     const errors: any = {};
  //     const errorMessages: any = {};

  //     if (!inputValue.name.match(/^[A-Za-z\s]+$/)) {
  //       errors.name = true;
  //       errorMessages.name = "Name should contain only letters and spaces";
  //       valid = false;
  //     }

  //     if (
  //       inputValue.cnic.length !== 15 ||
  //       !/^[0-9]{5}-[0-9]{7}-[0-9]{1}$/.test(inputValue.cnic)
  //     ) {
  //       errors.cnic = true;
  //       errorMessages.cnic = "CNIC should be in the format xxxxx-xxxxxxx-x";
  //       valid = false;
  //     }

  //     setInputError((prevState) => ({ ...prevState, ...errors }));
  //     setErrorText((prevState) => ({ ...prevState, ...errorMessages }));
  //     return valid;
  //   };
  const validateInput = (): boolean => {
    let valid = true;
    const errors: any = {};
    const errorMessages: any = {};

    // Validate name: should contain only letters and spaces, and cannot be empty
    if (!inputValue.name.trim() || !inputValue.name.match(/^[A-Za-z\s]+$/)) {
      errors.name = true;
      errorMessages.name =
        "Name should contain only letters and cannot be empty";
      valid = false;
    }

    // Validate CNIC: must be 15 characters long and match the format xxxxx-xxxxxxx-x
    if (
      !inputValue.cnic ||
      inputValue.cnic.length !== 15 ||
      !/^[0-9]{5}-[0-9]{7}-[0-9]{1}$/.test(inputValue.cnic)
    ) {
      errors.cnic = true;
      errorMessages.cnic = "CNIC should be in the format xxxxx-xxxxxxx-x";
      valid = false;
    }

    // Validate phone make: cannot be empty
    if (!inputValue.phone_make.trim()) {
      errors.phone_make = true;
      errorMessages.phone_make = "Phone make cannot be empty";
      valid = false;
    }

    // Validate phone model: cannot be empty
    if (!inputValue.phone_model.trim()) {
      errors.phone_model = true;
      errorMessages.phone_model = "Phone model cannot be empty";
      valid = false;
    }

    // Validate IMEI 1: must be 15 characters long
    if (!inputValue.imei_1 || inputValue.imei_1.length !== 15) {
      errors.imei_1 = true;
      errorMessages.imei_1 = "IMEI 1 must be 15 characters long";
      valid = false;
    }

    // Validate IMEI 2: optional but if provided, must be 15 characters long
    if (inputValue.imei_2 && inputValue.imei_2.length !== 15) {
      errors.imei_2 = true;
      errorMessages.imei_2 = "IMEI 2 must be 15 characters long if provided";
      valid = false;
    }

    // Validate IMEI 1: cannot be empty
    // if (!inputValue.imei_1.trim()) {
    //   errors.imei_1 = true;
    //   errorMessages.imei_1 = "IMEI 1 cannot be empty";
    //   valid = false;
    // }

    // IMEI 2 is optional, so no validation needed

    setInputError((prevState) => ({ ...prevState, ...errors }));
    setErrorText((prevState) => ({ ...prevState, ...errorMessages }));

    return valid;
  };

  const OnPressSendOTPButton = () => {
    if (validateInput()) {
      handleSendOTP();
    }
  };

  const handleMsisdnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    let regex = new RegExp("^(3[0-9]*|)$");

    if (regex.test(input)) {
      setMsisdn(input);
    }
  };

  const handleOnPressSendOTPButton = async () => {
    setErrorMsg("");
    if (msisdn.length === 10) {
      handleCheckOperator();
    } else {
      setErrorMsg("Please enter a valid 10-digit phone number");
    }
  };

  const handleCheckOperator = async () => {
    try {
      setIsLoading(true);
      let response = await checkOperatorService({ msisdn });
      console.log("isOther Check: ", response.result.isOther);
      if (response.result.isOther === false) {
        setIsUserProfileModalVisible(!isUserProfileModalVisible);
        let _package = response.result.package;
      } else {
        setIsLoading(false);
        setIsOtpLoading(false);
        setErrorMsg("Please enter a Jazz number to proceed");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg("Something went wrong.Please try again");
      console.log("Error while sending OTP: ", error);
    }
  };

  const handleSendOTP = async () => {
    try {
      setIsOtpLoading(true);
      let response = await generateWaadaOTPService({ msisdn });

      setIsUserProfileModalVisible(false);
      setShowLoginButton(true);
      setShowOtp(true);
      console.log("OTP send successfully: ", response.result);
      tagManagerEvents("Handset_Get_Otp", utmSource);
      setErrorMsg("");
      setCounter(30);
      setIsCounting(true);
    } catch (error) {
      setShowOtp(false);
      setShowLoginButton(false);
      setIsOtpLoading(false);
      setErrorMsg("Something went wrong.Please try again");
    }
    // finally {
    //   setShowLoginButton(false);
    //   setIsLoading(false);
    // }
  };

  //   Otp
  const handlePINInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      handleCodeChange(index, value);
      if (index < inputRefs.current.length - 1) {
        // Move focus to the next input field
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleCodeChange = (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...otp];
      newCode[index] = value;
      setOtp(newCode);
    }
  };

  const handlePINInput = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && otp[index] !== "") {
      if (index >= 0) {
        handleCodeChange(index, "");
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        } else {
          inputRefs.current[index]?.focus();
        }
      }
    }
  };

  const handleOTPSubmit = () => {
    let _otp = otp.join("");
    if (_otp.length === 4) {
      setErrorMsg("");
      loginUser(_otp);
    } else {
      setErrorMsg("Please enter a valid 4-digit OTP.");
    }
  };

  const loginUser = async (_otp: string) => {
    try {
      setIsLoginLoading(true);
      let data = {
        msisdn: msisdn,
        otp: _otp,
        name: inputValue.name,
        cnic: parseInt(inputValue.cnic.replaceAll("-", ""), 10),
        handSetInfo: {
          phone_make: inputValue.phone_make,
          phone_model: inputValue.phone_model,
          imei_1: inputValue.imei_1,
          imei_2: inputValue.imei_2,
        },
      };
      let response = await activateMobileInsuranceService(data);

      setIsLoginLoading(false);
      tagManagerEvents("Handset_Purchased", utmSource);

      navigate("/home");
    } catch (error) {
      setIsLoginLoading(false);
      console.log("Error while checking user: ", error);
    }
  };

  const backButton = () => {
    setShowOtp(false);
    setShowLoginButton(false);
  };

  return (
    <LandingOne
      msisdn={msisdn}
      errorMsg={errorMsg}
      isLoading={isLoading}
      handleMsisdnChange={handleMsisdnChange}
      OnPressSendOTPButton={OnPressSendOTPButton}
      showOtp={showOtp}
      inputValue={inputValue}
      inputError={inputError}
      errorText={errorText}
      handleInputChange={handleInputChange}
      handleResendClick={handleResendClick}
      onHandleCloseUserProfileModal={onHandleCloseUserProfileModal}
      isUserProfileModalVisible={isUserProfileModalVisible}
      isCounting={isCounting}
      counter={counter}
      isOTPSending={isOTPSending}
      otp={otp}
      inputRefs={inputRefs}
      handlePINInputChange={handlePINInputChange}
      handleOTPSubmit={handleOTPSubmit}
      handlePINInput={handlePINInput}
      showLoginButton={showLoginButton}
      backButton={backButton}
      isLoginLoading={isLoginLoading}
      isOtpLoading={isOtpLoading}
    />
  );
};

export default LandingWaadaScreen;
