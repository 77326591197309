import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pin from "../components/Pin";
import {
  checkUserService,
  generateOTPService,
  LoginService,
  subscriptionService,
} from "../services/LandingService";
import { tagManagerEvents } from "../utils/GoogleTagManager";
import { getErrorData } from "../utils/CommonFunctions";
import EventsName from "../constants/EventsName";

let msisdn = "";
let _package = "";
const OTPEntryScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOTPSending, setIsOTPSending] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  // const { msisdn, _package } = location.state as {
  //   msisdn: string;
  //   _package: string;
  // };

  const [timer, setTimer] = useState<number>(30); // Initial countdown time (e.g., 60 seconds)
  const [showResendButton, setShowResendButton] = useState<boolean>(false);

  useEffect(() => {
    if (location.state === null) {
      navigate("/landing");
    } else {
      let state = location.state as {
        msisdn: string;
        _package: string;
      };
      if (!state.msisdn || !state._package) {
        navigate("/landing");
      } else {
        msisdn = state.msisdn;
        _package = state._package;
      }
    }
  }, []);
  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown); // Cleanup the timer on unmount or before starting a new one
    } else {
      setShowResendButton(true);
    }
  }, [timer]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus(); // Focus on the first input
    }
  }, []);

  const handlePINInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      handleCodeChange(index, value);
      if (index < inputRefs.current.length - 1) {
        // Move focus to the next input field
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleCodeChange = (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...otp];
      newCode[index] = value;
      setOtp(newCode);
    }
  };

  const handlePINInput = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && otp[index] !== "") {
      if (index >= 0) {
        handleCodeChange(index, "");
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        } else {
          inputRefs.current[index]?.focus();
        }
      }
    }
  };

  const handleResendOTP = async () => {
    try {
      setIsOTPSending(true);
      let response = await generateOTPService({ msisdn });
      if (response.result) {
        setTimer(30);
        setShowResendButton(false);
        setErrorMsg("");
        console.log("OTP send successfully: ", response.result);
        setIsOTPSending(false);
        // navigate("/OTPEntry", { state: { msisdn, _package } });
      } else {
        setIsOTPSending(false);
        setErrorMsg("Error while sending OTP");
      }
    } catch (error) {
      setIsOTPSending(false);
      setErrorMsg("Error while sending OTP");
      console.log("Error while sending OTP: ", error);
    }
  };

  const handleOTPSubmit = () => {
    console.log("OTP Entered is: ", otp.join(""));
    let _otp = otp.join("");
    if (_otp.length === 4) {
      setErrorMsg("");
      handleCheckUser(_otp);
    } else {
      setErrorMsg("Please enter a valid 4-digit OTP.");
    }
  };

  const handleCheckUser = async (_otp: string) => {
    try {
      setIsLoading(true);
      let response = await checkUserService({ msisdn });
      if (response.result.active === true) {
        console.log("User is active");
        handleLoginUser(_otp);
      } else {
        console.log("User is not active");
        handleSubscribeUser(_otp);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error while checking user: ", error);
    }
  };

  const handleSubscribeUser = async (_otp: string) => {
    console.log("I am in handle Subscribe User");
    try {
      let response = await subscriptionService({
        msisdn: msisdn,
        otp: _otp,
        device_info: navigator.userAgent,
        sub_package: _package,
      });
      console.log("User Subscription Response: ", response);
      if (response.result) {
        tagManagerEvents(EventsName.subscribe, "");
        console.log("User Subscribed Successfully");
        let user_Id = response.result;
        localStorage.setItem("user_Id", user_Id);
        localStorage.setItem("msisdn", msisdn);
        localStorage.setItem("subpackage", _package);

        navigate("/home", { state: { user_Id } });
      }
    } catch (error) {
      if (getErrorData(error) === "otp verification failed") {
        setErrorMsg("Invalid OTP. Please enter a valid OTP.");
      } else {
        setErrorMsg("Unable to subscribe user.");
      }
      console.log("Error while subscribing user: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginUser = async (_otp: string) => {
    console.log("I am in handle Subscribe User");
    try {
      let response = await LoginService({
        msisdn: msisdn,
        otp: _otp,
        device_info: navigator.userAgent,
        sub_package: _package,
      });
      console.log("User Login Response: ", response);
      if (response.result) {
        tagManagerEvents(EventsName.login, "");
        console.log("User Logged In Successfully");
        let user_Id = response.result;
        localStorage.setItem("user_Id", user_Id);
        localStorage.setItem("msisdn", msisdn);
        localStorage.setItem("subpackage", _package);
        navigate("/home", { state: { user_Id } });
      }
    } catch (error) {
      if (getErrorData(error) === "otp verification failed") {
        setErrorMsg("Invalid OTP. Please enter a valid OTP.");
      } else {
        setErrorMsg("Unable to subscribe user.");
      }
      console.log("Error while logging in user: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Pin
      otp={otp}
      isLoading={isLoading}
      inputRefs={inputRefs}
      errorMsg={errorMsg}
      handlePINInputChange={handlePINInputChange}
      handlePINInput={handlePINInput}
      handleResendOTP={handleResendOTP}
      showResendButton={showResendButton}
      timer={timer}
      handleOTPSubmit={handleOTPSubmit}
      isOTPSending={isOTPSending}
    />
  );
};

export default OTPEntryScreen;
