import React, { useState, useEffect } from "react";
import landingOneLogo from "../Assets/imgs/landingOne-assets/waada-logo-img.webp";
import landingOneWadaImg from "../Assets/imgs/landingOne-assets/wada.webp";
import firkFormLogoImg from "../Assets/imgs/landingOne-assets/fikr-logo.webp";
import "../styles/LandingOne.css";
import UserProfileWaddaPopup from "./popups/UserProfileWaddaPopup";
import BackArrow from "../Assets/imgs/back-arrow.webp";

interface LandingProps {
  msisdn: string;
  handleMsisdnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  OnPressSendOTPButton: () => void;
  errorMsg: string;
  isLoading: boolean;
  showOtp: boolean;
  handleResendClick: () => void;
  onHandleCloseUserProfileModal: () => void;
  isUserProfileModalVisible: boolean;
  isCounting: boolean;
  counter: number;
  isOTPSending: boolean;
  isOtpLoading: boolean;
  backButton: () => void;
  otp: any;
  inputRefs: any;
  handleOTPSubmit: () => void;
  handlePINInputChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  showLoginButton: boolean;
  // showError: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoginLoading: boolean;
  handlePINInput: (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => void;
  inputValue: {
    name: string;
    cnic: string;
    phone_make: string;
    phone_model: string;
    imei_1: string;
    imei_2: string;
  };
  inputError: {
    name: boolean;
    cnic: boolean;
    phone_make: boolean;
    phone_model: boolean;
    imei_1: boolean;
    imei_2: boolean;
  };
  errorText: {
    name: string;
    cnic: string;
    phone_make: string;
    phone_model: string;
    imei_1: string;
    imei_2: string;
  };
}

const LandingOne = (props: LandingProps) => {
  console.log("showLoginButton::", props.showLoginButton);
  return (
    <div>
      <UserProfileWaddaPopup
        inputValue={props.inputValue}
        inputError={props.inputError}
        errorText={props.errorText}
        OnPressSendOTPButton={props.OnPressSendOTPButton}
        isOtpLoading={props.isOtpLoading}
        isUserProfileModalVisible={props.isUserProfileModalVisible}
        onHandleCloseUserProfileModal={props.onHandleCloseUserProfileModal}
        handleInputChange={props.handleInputChange}
        errorMsg={props.errorMsg}
      />
      <div className="landing-sectionOne">
        <div className="landing-content">
          <div className="logo-img">
            <img src={landingOneLogo} alt="" width="120" height="60" />
          </div>
          <div className="landing-home-img">
            <img src={landingOneWadaImg} alt="" width="375" height="291" />
          </div>
          <div className="fikr-landing-form">
            <div className="fikr-form">
              <div className="fikr-form-logo">
                <img src={firkFormLogoImg} alt="" width="70" height="58" />
              </div>
              {props.showOtp == false && (
                <>
                  <div className="text">Please enter your phone number</div>
                  <div className="fikr-input">
                    <span>+92</span>
                    <input
                      type="tel"
                      value={props.msisdn}
                      placeholder="Enter your Phone Number"
                      onChange={props.handleMsisdnChange}
                      maxLength={10}
                    />
                  </div>
                </>
              )}

              {/* OTPPPPPPP */}
              {props.showOtp && (
                <>
                  <div className="back__arrow">
                    <img
                      src={BackArrow}
                      alt="backarrow"
                      width={25}
                      height={25}
                      onClick={props.backButton}
                    />
                  </div>

                  <div className="otp-form">
                    <div className="text">
                      Please enter OTP received via SMS
                    </div>
                    <div className="fikr-input">
                      {props.otp.map((value: any, index: any) => (
                        <input
                          key={index}
                          ref={(el) => (props.inputRefs.current[index] = el)}
                          type="tel"
                          name="one"
                          maxLength={1}
                          value={value}
                          onChange={(e) => props.handlePINInputChange(index, e)}
                          onKeyDown={(e) => props.handlePINInput(index, e)}
                        />
                      ))}
                    </div>

                    {/* <p className="error">error</p> */}

                    <div className="charges-text">
                      <div>
                        <div
                          className="__resendOtp"
                          style={{
                            display: props.isCounting ? "none" : "block",
                          }}
                        >
                          Didn't receive the OTP?{" "}
                          {props.isOTPSending ? (
                            <span>Resending OTP</span>
                          ) : (
                            <span
                              id="otresendSpan"
                              onClick={props.handleResendClick}
                            >
                              Resend
                            </span>
                          )}
                        </div>

                        <div
                          className="otcounter"
                          id="otcounter"
                          style={{
                            display: props.isCounting ? "block" : "none",
                          }}
                        >
                          00:
                          {props.counter < 10
                            ? `0${props.counter}`
                            : props.counter}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* END */}
              {/* <p className="error">error</p> */}

              {props.errorMsg.length > 0 ? (
                <p className="error">{props.errorMsg}</p>
              ) : null}
              {props.showLoginButton ? (
                <>
                  {props.isLoginLoading ? (
                    <button>Verifying</button>
                  ) : (
                    <button onClick={props.handleOTPSubmit}>LOGIN</button>
                  )}
                </>
              ) : (
                <button
                  onClick={() => {
                    props.onHandleCloseUserProfileModal();
                  }}
                >
                  Proceed
                </button>
              )}

              <div className="charges-text">Price Point : Rs. 2.00 per day</div>
              <div className="term-conditon">
                <a href="/privacy-policy" target="_blank">
                  {" "}
                  Privacy Policy{" "}
                </a>
                <div className="dot"></div>
                <a href="/terms" target="_blank">
                  {" "}
                  Terms & Conditions{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingOne;
