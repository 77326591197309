import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "../../styles/insuranceInfoPopup.css";
import sliderlogo from "../../Assets/imgs/slider-logo.webp";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper/modules";
// swiper

interface InsurranceSliderProps {
  partnerPolicies: any;
  isPartnerPoliciesModalVisible: boolean;
  onHandleClosePartnerPolciesModal: () => void;
  onPartnerPolicyPressed: (policy_id: number, policyTitle: string) => void;
  selectedPartner: any;
  isLoading: boolean;
}

const createMarkup = (data: string) => {
  return { __html: data };
};

const InsurranceSlider = (props: InsurranceSliderProps) => {
  return (
    <>
      <Modal
        show={props.isPartnerPoliciesModalVisible}
        size="xl"
        className="modal-provide insur-slider"
        onHide={props.onHandleClosePartnerPolciesModal}
      >
        <div className="modal-parent">
          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            navigation={true}
            centeredSlides={true}
            modules={[Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              991: {
                slidesPerView: 3,
              },
            }}
          >
            {props.partnerPolicies.map((item: any, index: number) => (
              <SwiperSlide>
                <div className="modal-content-body">
                  <div className="_m-img">
                    <img src={props.selectedPartner.logo} alt="afu" />
                  </div>

                  <div>
                    <div className="new-modal-heading">{item.title}</div>
                    {/* <div className="modal-desc">{item.description}</div> */}
                    <div
                      dangerouslySetInnerHTML={createMarkup(item.description)}
                    ></div>
                    <div
                      className="download-btn"
                      style={{
                        pointerEvents: props.isLoading ? "none" : "auto",
                        opacity: props.isLoading ? 0.5 : 1,
                      }}
                      onClick={() =>
                        props.onPartnerPolicyPressed(item.id, item.title)
                      }
                    >
                      <a>Buy Now</a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Modal>
    </>
  );
};

export default InsurranceSlider;
